import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { ProductPensions } from 'common/components/templates/ProductPensions'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ProductPensionsPageProps {
    pageContext: any
}

export const ProductPensionsPage: React.FC<ProductPensionsPageProps> = ({
    pageContext
}) => {
    return <ProductPensions {...getProductPensionsData(pageContext)} />
}

export default ProductPensionsPage
export const getProductPensionsData = (data: any) => ({
    layout: getLayoutData(data),
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },

    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.productlist__heading,
        text: data.productlist__text,
        title: data.productlist__title,
        products: (data.products || ([] as Array<any>)).map((product: any) => ({
            icon: product.productlist__icon,
            title: product.title || product.jumbotron__title,
            text: product.productlist__summary,
            callToAction: product.downloads && product.downloads.map((item: any) => {
                return {
                    label: item.name || item.label,
                    url: item.file || item.url,
                    variant: 'download'
                }
            })
        }))
    }
})
