import { Spacer } from 'common/components/molecules/Spacer'
import { Alerts, AlertsProps } from 'common/components/organisms/Alerts'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import React from 'react'

export interface ProductPensionsProps {
    alerts: AlertsProps
    jumbotron: JumbotronProps
    layout: LayoutProps
    productList?: ProductListProps
}

export const ProductPensions: React.FC<ProductPensionsProps> = ({
    alerts,
    jumbotron,
    layout,
    productList
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <>{productList && <ProductList {...productList} />}</>
            <Spacer />
            <>{alerts && <Alerts {...alerts} />}</>
        </Layout>
    )
}
